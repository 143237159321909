import { sendAnalyticEvent } from "src/utils/analytics/sendAnalyticEvent";

export const sendShowOrderInfo = () => {
  sendAnalyticEvent({
    event: "order_info_shown",
  });
};


export const sendCloseOrderInfo = () => {
  sendAnalyticEvent({
    event: "order_info_closed",
  });
};

export const sendOrderClick = () => {
  sendAnalyticEvent({
    event: "order_info_click",
  });
};
