import dynamic from 'next/dynamic';
import { Fragment, MouseEvent, useState } from 'react';
import { Banner } from 'src/api/types';
import { useTheme } from 'src/ui-kit/theme';
import { MOBILE_PLATFORM } from '../../data/constants';
import useLang from '../../services/language/useLang';
import { MIN_DESKTOP_WIDTH } from '../../styles/media';
import { sendBannerClick, sendBannerImpression } from '../../utils/enhancedEcommerce';
import Impressionable from '../Impressionable';
import Slider from '../SliderV2';
import { useSlider } from '../SliderV2/helpers/useSlider';
import DefaultSlide from './DefaultSlide';
import { HomeSliderStyled } from './HomeSlider.styles';

const HomeSliderControls = dynamic(
  () => import('./HomeSliderControls'),
  { ssr: false },
);

interface Props {
  banners: Banner[];
}

const HomeSlider = (props: Props) => {
  const { banners } = props;
  const [isInteracted, setIsInteracted] = useState(false);
  const language = useLang();
  const theme = useTheme();

  const isAutoScrollEnabled =
    typeof window !== 'undefined' &&
    window.innerWidth > MIN_DESKTOP_WIDTH &&
    !MOBILE_PLATFORM && !isInteracted;

  const { sliderRef, controls, sliderControls } = useSlider({
    withAutoScroll: isAutoScrollEnabled,
    autoScrollTimer: 5000,
    duration: 1000,
    withSwiperMode: true,
    endlessLeft: true,
    endlessRight: true,
  });

  const handleBannerClick = (banner: Banner, index: number, targetType: string) => (event: MouseEvent<HTMLAnchorElement>) => {
    if (typeof window.ga === 'function') {
      event.preventDefault();
      const destinationUrl = banner.image[language].link;
      /**
       * Google analytics docs recommends an alternative way to execute main functionality,
       * in case of gtag.js library fails to run
       */
      setTimeout(() => {
        window.open(destinationUrl, targetType);
      }, 1000);
      sendBannerClick({
        bannerId: banner.id,
        bannerName: banner.name,
        destinationUrl,
        bannerPosition: `slot${index}`,
        targetType,
      });
    }
  };

  const handleBannerImpression = (banner, index) => () => {
    sendBannerImpression({
      bannerId: banner.id,
      bannerName: banner.name,
      bannerPosition: `slot${index}`,
    });
  };

  if (banners.length < 1) {
    return null;
  }


  return (
    <Fragment>
      <HomeSliderStyled
        theme={theme}
        className='HomeSlider'
        onClick={() => setIsInteracted(true)}
      >
        <Slider
          sliderRef={sliderRef}
          controls={sliderControls}
          customNavigation={
            <HomeSliderControls
              banners={banners}
              onInteract={setIsInteracted}
              controls={controls}
            />
          }
        >
          {banners.map((banner, index) => {
            const bannerTarget = banner.target_blank ? '_blank' : '_self';

            return (
              <a
                key={banner.id}
                className='HomeSlider__slide'
                href={banner.image[language].link}
                target={bannerTarget}
                rel="noopener noreferrer"
                data-testid='HomeSlider__banner'
                data-marker='Home Slider Banner'
                onClick={handleBannerClick(
                  banner,
                  index,
                  bannerTarget)
                }
              >
                <Impressionable onImpressed={handleBannerImpression(banner, index)}>
                  <DefaultSlide
                    image={banner.image[language]}
                    theme={theme}
                    currentLocale={language}
                  />
                </Impressionable>
              </a>
            );
          })}
        </Slider>
      </HomeSliderStyled>
    </Fragment>
  );
};

export default HomeSlider;
