'use client';
import dynamic from 'next/dynamic';
import { Banner, RetailChain } from 'src/api/types';
import AppSection from 'src/components/AppSection/AppSection';
import HomeHero from 'src/components/HomeHero/HomeHero';
import HomeRetails from 'src/components/HomeRetails/HomeRetails';
import HomeSlider from 'src/components/HomeSlider/HomeSlider';
import HowSection from 'src/components/HowSection';
import PromotionSection from 'src/components/PromotionSection';
import { NEW_YEAR_AVAILABLE, WITH_UBERCATALOG } from 'src/data/constants';
import { NearestActiveOrder } from 'src/modules/nearestActiveOrder';
import SearchAndCategoriesSlider from '../SearchAndCategoriesSlider/SearchAndCategoriesSlider';

const NewYearAnimation = dynamic(
  () => import('src/components/NewYear/NewYearAnimation'),
  { ssr: false },
);

interface Props {
  chains: RetailChain[];
  banners: Banner[];
}

function HomePage(props: Props) {
  const { chains, banners } = props;

  return (
    <>
      <HomeHero />
      <HomeRetails />
      <HomeSlider banners={banners} />
      <AppSection chainsCount={chains.length} />

      {WITH_UBERCATALOG && (
        <SearchAndCategoriesSlider />
      )}

      {chains && (
        <PromotionSection
          chains={chains}
        />
      )}

      {NEW_YEAR_AVAILABLE && (
        <NewYearAnimation />
      )}

      <HowSection />
      <NearestActiveOrder chains={chains} />
    </>
  );
}

export default HomePage;
