import Link from 'next/link';
import { useTheme } from 'src/ui-kit/theme';
import { Typography } from '../../ui-kit/Typography';
import { SeeMoreCardStyled } from './SeeMoreCard.styles';
import { ReactNode } from 'react';

interface Props {
  linkHref: string;
  linkText: ReactNode;
  isNextLink?: boolean;
  dataMarker?: string;
}

const SeeMoreCard = (props: Props) => {
  const {
    linkHref,
    linkText,
    isNextLink = false,
    dataMarker = 'SeeMoreCard',
  } = props;

  const theme = useTheme();

  if (isNextLink) {
    return (
      <Link href={linkHref} legacyBehavior>
        <SeeMoreCardStyled
          theme={theme}
          className='SeeMoreCard'
          data-marker={dataMarker}
        >
          <div className='SeeMoreCard__content'>
            <div className='SeeMoreCard__img'/>
            <Typography element='span' variant='text3' color='primary'>{linkText}</Typography>
          </div>
        </SeeMoreCardStyled>
      </Link>
    );
  }

  return (
    <SeeMoreCardStyled
      theme={theme}
      className='SeeMoreCard'
      target='_blank'
      href={linkHref}
      rel='noreferrer'
      data-marker={dataMarker}
    >
      <div className='SeeMoreCard__content'>
        <div className='SeeMoreCard__img'/>
        <Typography element='span' variant='text3' color='primary'>{linkText}</Typography>
      </div>
    </SeeMoreCardStyled>
  );
};

export default SeeMoreCard;
