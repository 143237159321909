import Image from 'next/image';
import { Category } from "src/api/types";
import { defaultProductImage } from 'src/fixtures';
import { LinkWithLoading } from 'src/modules/global-loading';
import { getCategoriesUrl, sendCategoryClick } from 'src/modules/ubercatalog';
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Theme } from 'src/ui-kit/theme/types';
import { Typography } from 'src/ui-kit/Typography';

interface CategoryTileProps {
  category: Category;
  language: string;
  theme: Theme;
  dataMarker?: string;
}

const CategoryTile = (props: CategoryTileProps) => {
  const { category, language, theme, dataMarker = 'CategoryTile' } = props;
  const {  id: categoryId, image_url, title } = category;

  return (
    <LinkWithLoading
      href={getCategoriesUrl({ lang: language, categoryId })}
      onClick={() => {
        sendCategoryClick({
          page: 'landing',
          place: 'category_category_tiles',
          category_id: categoryId,
        });
      }}
      data-marker={dataMarker}
    >
      <StylishBox
        sbs={{
          overflow: 'hidden',
          position: 'relative',
          p: {
            general: '16px',
            phone: '12px 8px',
          },
          width: {
            general: '100%',
            phone: '91px',
          },
          height: {
            general: '115px',
            phone: '155px',
          },
          backgroundColor: image_url?.main_category_preview_background,
          borderRadius: theme.base.borderRadii.v1,
          hover: {
            boxShadow: theme.base.boxShadow.v1,
          },
          border: theme.base.border.v1,
          borderColor: theme.base.color.gray200,
        }}
      >
        <StylishBox
          sbs={{
            position: 'absolute',
            top: {
              desktopTablet: '0',
            },
            right: '0',
            bottom: {
              phone: '0',
            },
            left: {
              phone: '0',
            },
          }}
        >
          <StylishBox
            sbs={{
              position: 'relative',
              width: {
                general: '132px',
                phone: '89px',
              },
              height: {
                general: '115px',
                phone: '77px',
              },
            }}
          >
            <Image
              src={image_url?.main_category_preview || defaultProductImage}
              alt={title}
              data-marker={`${dataMarker} image`}
              fill
            />
          </StylishBox>
        </StylishBox>
        <Typography variant="text3"
          sbs={{
            lineHeight: {
              general: '19px',
              phone: '16px',
            },
            fontSize: {
              phone: '12px',
            },
            maxWidth: {
              desktopTablet: '120px',
            },
            wordBreak: {
              phone: 'break-word',
            },
          }}
          dataMarker={`${dataMarker} title`}
        >
          {title}
        </Typography>
      </StylishBox>
    </LinkWithLoading>
  );
};

export default CategoryTile;
