import { Theme } from 'src/ui-kit/theme/types';
import { phone, tablet_desktop } from '../../styles/media';
import { DefaultSlideStyled } from './DefaultSlide.styles';
import { BannerImage } from 'src/api/types';

interface Props {
  image: BannerImage;
  currentLocale: string;
  theme: Theme;
}

const DefaultSlide = (props: Props) => {
  const { image, theme } = props;

  return (
    <DefaultSlideStyled
      theme={theme}
      className='DefaultSlide'
    >
      <picture>
        <source media={tablet_desktop} srcSet={image.desktop.url} />
        <source
          media={phone}
          srcSet={image.mobile.url}
        />
        <img
          className='DefaultSlide__image'
          src={image.mobile.url}
          alt='Banner'
        />
      </picture>
    </DefaultSlideStyled>
  );
};

export default DefaultSlide;
