import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import { desktop, phone } from '../../styles/media';

export const HomeSliderStyled = styled.div<StyledThemeProps>(({ theme }) => `
  max-width: 1136px;
  max-height: 356px;
  margin: 80px 32px;
  position: relative;

  .HomeSlider__placeholder {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: ${theme.base.color.gray200};
    z-index: 3;
  }
  .HomeSlider__slide {
    position: relative;
    line-height: 0;
    width: 100%;
    flex-shrink: 0;
    cursor: pointer;
  }

  @media ${desktop} {
    height: 356px;
    margin: 80px auto;
    overflow: hidden;
  }

  @media ${phone} {
    width: 100%;
    max-height: initial;
    margin: 14px 0 48px;
  }
`);
