import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import { desktop } from '../../styles/media';

export const SeeMoreCardStyled = styled.a<StyledThemeProps>(({ theme }) => `
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${ theme.base.color.white };
  text-decoration: none;

  .SeeMoreCard__img {
    margin-bottom: 12px;
    height: 40px;
    width: 40px;

    border-radius: 50%;
    border: 1px solid ${ theme.base.color.gray200 };
    transition: 0.2s ease-in-out;

    background-image: url(/static/images/seeMoreLogo.svg);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: center center;
  }

  .SeeMoreCard__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${ desktop } {
    &:hover .SeeMoreCard_img {
      border: 1px solid ${ theme.base.color.primary };
    }
  }
`);

